// 过滤格式，将格式生成成符合集联选择器的格式
export function generatePairs(original, options, level = null) {
  const result = [];
  let locations = new Set(original.location || []);

  original.region.forEach((parent) => {
    const parentObj = options.find((r) => r.value === parent);

    if (parentObj && parentObj.children) {
      // 如果 location 为空数组，则添加所有子项
      if (original.location.length === 0) {
        parentObj.children.forEach((child) => {
          result.push([parent, child.value]);
        });
      } else {
        // 原有逻辑
        original.location.forEach((child) => {
          const childValue = parentObj.children.find((c) => {
            let matched = c.value === child;
            if (matched) {
              locations.delete(child);
            }
            return matched;
          });
          if (childValue) {
            result.push([parent, child]);
          }
        });
      }
    }
  });

  if (level == 1) {
    Array.from(locations).forEach((child) => {
      result.push([child]);
    });
  }

  return result;
}

// 还原入参格式
export function restoreOriginalObject(pairs, level = null) {
  const originalObject = {
    region: [],
    location: []
  };

  pairs.forEach((pair) => {
    if (level == 1) {
      const [location] = pair;
      originalObject.location.push(location);
    } else {
      const [region, location] = pair;

      if (!originalObject.region.includes(region)) {
        originalObject.region.push(region);
      }

      if (location) {
        originalObject.location.push(location);
      }
    }
  });

  return originalObject;
}

export function getOneLabel(options, seoValue) {
  return options.reduce((acc, option) => {
    const found = option.children.find((item) => item.value === seoValue);
    return found ? found.label : acc;
  }, null);
}

export function getOneRegion(options, seoValue) {
  return options.find((item) => {
    return item.value === seoValue;
  });
}
