"use client";
import React, { useEffect, useRef, useState } from "react";
import styles from "../../../styles/index.pc.module.scss";
import Loading from "./Loading";
import { JobTransProvider } from "app/(new-pages)/components/JobDetail/jobTransProvider";
import { getCountryKey } from "helpers/country";
import JobDetail from "app/(new-pages)/components/JobDetail/HomeJobDetail/JobDetail.pc";
import NetworkError from "./NetworkError";

import { fetchJobDetail } from "../../../services/index";
import { addEventTag } from "helpers/utilities";
import JobCardPc from "./JobCard.pc";
import { addJobViewService } from "store/services/jobs/addJobView";
import { getDeviceNameTracker, viewJobSourceTracker } from "components/ReportTracker/util";
import { getDeviceUuid } from "helpers/guest";
import classNames from "classnames";

const googleTag = ["hp_job_for_you_card_click", "hp_popular_jobs_card_click"];

const Card = ({ jobs, id, sessionid, cardType }) => {
  const [detail, setDetail] = useState<any>(jobs[0]?.job_detail || {});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const countryKey = getCountryKey();
  const [error, setError] = useState(false);
  const detailRef = useRef(null);
  const source = sessionid ? viewJobSourceTracker.homeForLogin : viewJobSourceTracker.homeForGuest;

  // console.log("Card@jobs:", { jobs, id, sessionid, detail, source });

  const addView = async ({ job_id, reco_from, query_id }) => {
    const device_udid = await getDeviceUuid();
    addJobViewService({
      jobId: job_id || null,
      source: source, // this is usually used in search result
      status: "public",
      device: getDeviceNameTracker(false), // pc
      device_udid,
      is_login: sessionid ? 1 : 0,
      reco_from: reco_from || null,
      query_id: query_id || null
    });
  };

  useEffect(() => {
    if (id) {
      setCurrentIndex(0);
      setDetail(jobs[0]?.job_detail || {});
      setError(!jobs[0]?.job_detail);
      addView({
        job_id: jobs[0]?.job_id,
        reco_from: jobs[0]?.reco_from,
        query_id: jobs[0]?.query_id
      });
    }
  }, [jobs]);

  function handleScroll() {
    var headerOffset = 100;
    var elementPosition = detailRef.current?.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    if (!detailRef.current) {
      return;
    }
    const contentEl = detailRef.current.querySelector(".job-main-inner");

    contentEl.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  const fetchDetail = async (job_id) => {
    try {
      setLoading(true);
      const res = await fetchJobDetail({ job_id });
      setLoading(false);
      if (res?.data?.code == 0) {
        setDetail(res.data.data);
        setError(false);
      } else {
        setError(true);
      }
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const changeDetail = (e, index) => {
    e.stopPropagation();
    const gtagEventName = sessionid ? googleTag[0] : googleTag[1];
    addEventTag(gtagEventName);
    fetchDetail(jobs[index].job_id);
    setCurrentIndex(index);
    handleScroll();
    addView({
      job_id: jobs[index]?.job_id,
      reco_from: jobs[index]?.reco_from,
      query_id: jobs[index]?.query_id
    });
  };

  // const processedData = jobs?.length % 2 !== 0 ? jobs?.slice(0, -1) : jobs;

  const processedData = React.useMemo(() => {
    return jobs?.length % 2 !== 0 ? jobs?.slice(0, -1) : jobs;
  }, [jobs]);

  return (
    <JobTransProvider data={detail}>
      <div className={classNames(styles.content, { [styles.contentRow]: cardType === "row" })}>
        <div className={classNames(styles.list, styles.listRow)}>
          {processedData?.map((item, index) => {
            return (
              <JobCardPc
                key={"" + item?.job_id + index}
                item={item}
                index={index}
                currentIndex={currentIndex}
                changeDetail={changeDetail}
                cardType={cardType}
              />
            );
          })}
        </div>
        <div className={styles.jobDetail}>
          {loading ? (
            <Loading />
          ) : error ? (
            <NetworkError onClick={() => fetchDetail(jobs[currentIndex]?.job_id)} />
          ) : (
            <JobDetail
              ref={detailRef}
              countryKey={countryKey}
              sessionid={sessionid}
              jobId={detail?.job_id}
              data={detail || {}}
            />
          )}
        </div>
      </div>
    </JobTransProvider>
  );
};

export default Card;
