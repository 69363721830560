"use client";
import React from "react";
import Link from "next/link";
import { addEventTag } from "helpers/utilities";
import { getCookie, setCookie } from "helpers/cookies";
import { setJobViewCookiesTracker, viewJobSourceTracker } from "components/ReportTracker/util";

const googleTag = ["hp_job_for_you_card_click", "hp_popular_jobs_card_click"];

interface LinkClientProps {
  children: React.ReactNode;
  href: string;
  className?: string;
  job: any;
  [key: string]: any;
}

const LinkClient = (props: LinkClientProps) => {
  const { children, job, ...rest } = props;
  const sessionId = getCookie("sessionid");
  const gtagName = sessionId ? googleTag[0] : googleTag[1];
  const source = sessionId ? viewJobSourceTracker.homeForLogin : viewJobSourceTracker.homeForGuest;

  // TODO
  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setJobViewCookiesTracker(setCookie, {
      source: source,
      reco_from: job?.reco_from ? job?.reco_from : null,
      query_id: job?.query_id ? job?.query_id : null
    });
    addEventTag(gtagName);
  };
  return (
    <Link {...rest} onClick={onClick}>
      {children}
    </Link>
  );
};

export default LinkClient;
