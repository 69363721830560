"use client";
import React, { useState } from "react";
import { debounce } from "lodash-es";
import { toPairs } from "ramda";

import { addEventTag, convertSpecialCharacters } from "helpers/utilities";
import useSearchHistory from "helpers/useSearchHistory";
import { fetchSearchSuggestionService } from "store/services/jobs/fetchSearchSuggestion";
import { zipLocation } from "../../services";
import { genWordLocationPath } from "../../helper";

// import HomeSearchMobile from "app/(new-pages)/components/HomeSearch/mobile/index.mobile";
import CommonSearchMobile from "app/(new-pages)/components/Search/mobile/index.mobile";

const transObject = (params) => {
  const result = {};
  params.forEach((e, index) => {
    result[`query_histories[${index}]`] = e;
  });
  return result;
};

export const handleFilterSuggestionList = (suggestionList = []) => {
  return suggestionList.map((item) => {
    if (item?.length && typeof item === "string") {
      return { value: item, label: item };
    }

    return {
      ...item,
      label: item?.value
    };
  });
};

const google_tags = ["hp_location_click", "hp_search_focus", "hp_search_click"];

interface SearchInputMobileProps {
  langKey: string;
  pageData: {
    search_info: any;
  };
}

const SearchInputMobile = (props: SearchInputMobileProps) => {
  const { langKey } = props;
  const search_info = props?.pageData?.search_info || {};
  // const location_lists = search_info?.location_lists || [];
  const [locationValue, setLocationValue] = useState({ region: [], location: [] });
  const [query, setQuery] = React.useState("");
  const [suggestionList, setSuggestionList] = useState([]);
  const [searchHistories, addSearchHistory] = useSearchHistory();
  const [selectQuery, setSelectQuery] = useState(null);

  const onSearch = (value, option) => {
    const isEvent = !option;

    const val = isEvent ? query : value;

    if (option) {
      setSelectQuery(option);
    }

    addSearchHistory(val);
    setQuery(val);

    addEventTag(google_tags[2]);

    if (option) {
      pushJobPage({ value: val, type: option?.type });
    } else {
      pushJobPage({ value: val, type: selectQuery?.type });
    }
  };

  const handleQuery = (val: string) => {
    setSelectQuery(null);
    setQuery(val);
    handleSuggestionSearch(val);
  };

  const pushJobPage = async ({ value = "", type = "" }) => {
    const keyWords = value?.trim?.().replace(/\s+/g, " ");
    const words = convertSpecialCharacters((keyWords || "").trim());

    const result = genWordLocationPath({
      locationValue: locationValue,
      keyword: encodeURIComponent(words)
    });

    let params = {};

    if (type) {
      params["queryFields"] = type;
    }

    if (result?.multipleLocation) {
      params["location"] = locationValue?.location;
      params["region"] = locationValue?.region;
    }

    const zippedParams = await zipLocation(params);
    const url = new URLSearchParams(toPairs(zippedParams)).toString();
    const { searchPath } = result;
    const urlPath = "/" + langKey + `/jobs-hiring/${searchPath}/` + (url ? "?" + url : "");

    window.location.href = urlPath;
  };

  const handleSuggestionSearch = debounce(async (val) => {
    const valueLength = val?.length ?? 0;
    if (valueLength < 1) {
      setSuggestionList(searchHistories as any);
    } else {
      const qs = transObject(searchHistories);
      fetchSearchSuggestionService({ size: 5, query: val, ...qs }).then((data) => {
        const items = data?.data?.data?.items || [];
        setSuggestionList(items);
      });
    }
  }, 300);

  const onFocus = () => {
    addEventTag(google_tags[1]);
    if (!query?.length) {
      setSuggestionList(searchHistories as any);
    }
  };

  return (
    <>
      <CommonSearchMobile
        query={query}
        setQuery={handleQuery}
        onSearch={onSearch}
        placeholder={search_info?.input_text}
        suggestionList={handleFilterSuggestionList(suggestionList)}
        onFocus={onFocus}
        inputOptions={{
          companyGroupText: search_info?.input_right_text,
          jobTitleGroupText: search_info?.input_left_text,
          historyGroupText: search_info?.search_history_text
        }}
      />
    </>
  );
};

export default SearchInputMobile;
