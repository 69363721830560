const SearchIconInPc = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='21' viewBox='0 0 22 21' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.3138 20.0001L10.5703 14.6062L11.8998 13.4117L16.6433 18.8056L15.3138 20.0001Z'
        fill='white'
        stroke='white'
        strokeWidth='0.937026'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.6762 4.12009C4.8927 4.12009 2.63623 6.40083 2.63623 9.21426C2.63623 12.0277 4.8927 14.3084 7.6762 14.3084C10.4597 14.3084 12.7162 12.0277 12.7162 9.21426C12.7162 6.40083 10.4597 4.12009 7.6762 4.12009ZM0.857422 9.21426C0.857422 5.40785 3.91029 2.32214 7.6762 2.32214C11.4421 2.32214 14.495 5.40785 14.495 9.21426C14.495 13.0207 11.4421 16.1064 7.6762 16.1064C3.91029 16.1064 0.857422 13.0207 0.857422 9.21426Z'
        fill='white'
        stroke='white'
        strokeWidth='0.468513'
      />
      <path
        d='M13.9375 4.23518C16.2614 4.23518 17.6558 2.49128 17.6558 0C17.6558 2.49128 19.2825 4.23518 21.1417 4.23518C19.2825 4.23518 17.6558 5.72995 17.6558 8.22123C17.6558 5.72995 16.2614 4.23518 13.9375 4.23518Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.3674 2.78057C16.9287 2.12411 17.2556 1.16925 17.2556 0H18.056C18.056 2.31603 19.5508 3.82412 21.1417 3.82412V4.64624C19.5196 4.64624 18.056 5.9402 18.056 8.22123H17.2556C17.2556 7.05316 16.9297 6.16862 16.3791 5.57835C15.8306 4.99031 15.0142 4.64624 13.9375 4.64624V3.82412C14.9953 3.82412 15.8112 3.43097 16.3674 2.78057ZM15.847 4.21091C16.2695 4.40535 16.6424 4.67223 16.9571 5.0096C17.2571 5.33119 17.4965 5.70863 17.6743 6.13395C18.066 5.25507 18.7055 4.59647 19.4772 4.21192C18.702 3.7827 18.0651 3.05766 17.6749 2.13778C17.4991 2.57995 17.2632 2.97886 16.9688 3.32307C16.6534 3.69192 16.2768 3.99102 15.847 4.21091Z'
        fill='white'
      />
    </svg>
  );
};

const SearchIconMobile = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.0668 21.0499L10.252 15.6333L11.6014 14.4337L16.4163 19.8504L15.0668 21.0499Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.31387 5.1028C4.48853 5.1028 2.19813 7.39319 2.19813 10.2185C2.19813 13.0439 4.48853 15.3343 7.31387 15.3343C10.1392 15.3343 12.4296 13.0439 12.4296 10.2185C12.4296 7.39319 10.1392 5.1028 7.31387 5.1028ZM0.392578 10.2185C0.392578 6.39601 3.49135 3.29724 7.31387 3.29724C11.1364 3.29724 14.2352 6.39601 14.2352 10.2185C14.2352 14.0411 11.1364 17.1398 7.31387 17.1398C3.49135 17.1398 0.392578 14.0411 0.392578 10.2185Z'
        fill='white'
      />
      <path
        d='M14.2949 5.13568C16.6538 5.13568 18.0691 3.41219 18.0691 0.950073C18.0691 3.41219 19.7203 5.13568 21.6074 5.13568C19.7203 5.13568 18.0691 6.61295 18.0691 9.07507C18.0691 6.61295 16.6538 5.13568 14.2949 5.13568Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.7613 3.6981C17.3311 3.04932 17.6629 2.10563 17.6629 0.950073H18.4754C18.4754 3.239 19.9926 4.72943 21.6074 4.72943V5.54193C19.9609 5.54193 18.4754 6.82074 18.4754 9.07507H17.6629C17.6629 7.92068 17.3321 7.04649 16.7732 6.46313C16.2165 5.88198 15.3878 5.54193 14.2949 5.54193V4.72943C15.3686 4.72943 16.1968 4.34088 16.7613 3.6981ZM16.2331 5.11169C16.6619 5.30386 17.0405 5.56762 17.3599 5.90104C17.6644 6.21886 17.9075 6.59188 18.0879 7.01222C18.4855 6.14363 19.1346 5.49274 19.9179 5.1127C19.131 4.68849 18.4845 3.97194 18.0886 3.06283C17.9101 3.49983 17.6706 3.89406 17.3718 4.23425C17.0517 4.59878 16.6693 4.89438 16.2331 5.11169Z'
        fill='white'
      />
    </svg>
  );
};

const LocationIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
      <path
        d='M9.00195 16.5039C10.502 13.5039 15.002 11.5676 15.002 7.50391C15.002 4.1902 12.3157 1.50391 9.00195 1.50391C5.68824 1.50391 3.00195 4.1902 3.00195 7.50391C3.00195 11.5676 7.50195 13.5039 9.00195 16.5039Z'
        stroke='black'
        strokeWidth='1.26'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 9.5625C10.2426 9.5625 11.25 8.55514 11.25 7.3125C11.25 6.06986 10.2426 5.0625 9 5.0625C7.75736 5.0625 6.75 6.06986 6.75 7.3125C6.75 8.55514 7.75736 9.5625 9 9.5625Z'
        stroke='black'
        strokeWidth='1.26'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const SearchWithStarIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.546 21.3885L12.1016 16.3885L13.3472 15.2812L17.7917 20.2812L16.546 21.3885Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.38889 6.66667C6.78088 6.66667 4.66667 8.78088 4.66667 11.3889C4.66667 13.9969 6.78088 16.1111 9.38889 16.1111C11.9969 16.1111 14.1111 13.9969 14.1111 11.3889C14.1111 8.78088 11.9969 6.66667 9.38889 6.66667ZM3 11.3889C3 7.8604 5.8604 5 9.38889 5C12.9174 5 15.7778 7.8604 15.7778 11.3889C15.7778 14.9174 12.9174 17.7778 9.38889 17.7778C5.8604 17.7778 3 14.9174 3 11.3889Z'
        fill='white'
      />
      <path
        d='M16 4.86364C18.1774 4.86364 19.4839 3.27273 19.4839 1C19.4839 3.27273 21.0081 4.86364 22.75 4.86364C21.0081 4.86364 19.4839 6.22727 19.4839 8.5C19.4839 6.22727 18.1774 4.86364 16 4.86364Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.2767 3.53664C18.8026 2.93776 19.1089 2.06667 19.1089 1H19.8589C19.8589 3.11285 21.2594 4.48864 22.75 4.48864V5.23864C21.2301 5.23864 19.8589 6.41908 19.8589 8.5H19.1089C19.1089 7.4344 18.8036 6.62746 18.2877 6.08897C17.7737 5.55252 17.0088 5.23864 16 5.23864V4.48864C16.9911 4.48864 17.7556 4.12998 18.2767 3.53664ZM17.7891 4.8415C18.1849 5.01888 18.5344 5.26235 18.8292 5.57012C19.1103 5.86349 19.3347 6.20782 19.5013 6.59583C19.8682 5.79405 20.4674 5.19323 21.1904 4.84242C20.4641 4.45085 19.8673 3.78941 19.5018 2.95024C19.3371 3.35362 19.116 3.71753 18.8402 4.03154C18.5447 4.36804 18.1918 4.64089 17.7891 4.8415Z'
        fill='white'
      />
    </svg>
  );
};

export { SearchIconInPc, SearchIconMobile, LocationIcon, SearchWithStarIcon };
