import React from "react";
import classNames from "classnames";

import InputInSearch from "./InputInSearch";
import { SearchPcPropsType } from "./type";
import { SearchIconInPc } from "../assets/icon";
import LocationSelectPc from "../../allSelect/LocationSelect/pc";

import styles from "./style/index.module.scss";

const SearchPc = ({
  location_filter = [],
  visibleLocation = true,
  locationValue,
  setLocationValue,
  query,
  setQuery,
  onSearch,
  inputPlaceholder,
  suggestionList,
  onFocus,
  locationLabel = "Location",
  locationPlaceholder = "Location",
  whereLabelText = "Where",
  inputSearchText = "Search",
  inputOptions = {
    companyGroupText: "Company name",
    jobTitleGroupText: "Job title",
    historyGroupText: "Search history"
  },
  locationOptions = {
    searchBoardPlaceholder: "Search",
    boardClearText: "Clear",
    boardApplyFiltersText: "Apply filter"
  }
}: SearchPcPropsType) => {
  //
  let region = locationValue?.region || [];
  let location = locationValue?.location || [];

  let showCount =
    region?.length > 1 ||
    location?.length > 1 ||
    (region.length === 1 && !location.length
      ? location_filter.find((item) => item.seo_value === region[0])?.locations?.length > 1
      : false);

  return (
    <div className={classNames(styles.containerPc, "yolo-searchArea-wrapper")}>
      {visibleLocation && (
        <div
          className={classNames({
            [styles.location]: true,
            [styles.showCount]: showCount
          })}
        >
          <LocationSelectPc
            location_filter={location_filter}
            value={locationValue}
            onChange={setLocationValue}
            variant='borderless'
            label={locationLabel}
            placeholder={locationPlaceholder}
            arrowIconStyle='dark'
            showWhereLabel={true}
            whereLabelText={whereLabelText}
            {...locationOptions}
          />
        </div>
      )}

      <div
        className={classNames({
          [styles.inputWrapper]: true,
          [styles.hideLocation]: !visibleLocation
        })}
      >
        <div
          className={classNames({
            [styles.input]: true,
            [styles.inputHasLocation]: visibleLocation
          })}
        >
          <InputInSearch
            setQuery={setQuery}
            query={query}
            placeholder={inputPlaceholder}
            suggestionList={suggestionList}
            onSearch={onSearch}
            onFocus={onFocus}
            inputOptions={inputOptions}
          />
        </div>

        <div className={classNames(styles.searchButton, "yolo-search-button")} onClick={onSearch}>
          <SearchIconInPc /> <span>{inputSearchText}</span>
        </div>
      </div>
    </div>
  );
};

export default SearchPc;
