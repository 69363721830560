"use client";
import React from "react";

import styles from "../../styles/index.mobile.module.scss";

import CompanyCardMobile from "./CardItem.mobile";
import LinkMoreMobileClient from "./LinkMore.mobile.client";

interface IProps {
  langKey: string;
  pageData: any;
  device_udid: string;
}

const CompaniesMobile = (props: IProps) => {
  const { langKey = "", device_udid } = props;
  const pageData = props?.pageData || {};
  const popular_companies = pageData?.popular_companies || {};
  const companies = popular_companies?.companies || [];

  if (!companies?.length) {
    return null;
  }

  const desc = popular_companies?.description || "";
  const highlight = popular_companies?.description_highlight_text || "";

  // replace highlight
  const descHtml = desc.replace(
    highlight,
    `<span class=${styles.companiesDescNum}>${highlight}</span>`
  );

  return (
    <section className={styles.companies}>
      <h2 className={styles.companiesTitle}>{popular_companies?.title}</h2>
      <p className={styles.companiesDesc} dangerouslySetInnerHTML={{ __html: descHtml }}></p>
      <ul className={styles.companiesList}>
        {companies.map((company) => {
          return (
            <li className={styles.companiesItem} key={company?.id}>
              <CompanyCardMobile device_udid={device_udid} company={company} langKey={langKey} />
            </li>
          );
        })}
      </ul>
      {popular_companies?.more_text && (
        <LinkMoreMobileClient data={popular_companies} langKey={langKey} />
      )}
    </section>
  );
};

export default CompaniesMobile;
