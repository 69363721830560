import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "../../../styles/index.pc.module.scss";
import Image from "next/image";
import classNames from "classnames";
import { Tooltip } from "antd";
import { getLang } from "helpers/country";
import { Company } from "../types";

interface SalaryInfo {
  text?: string;
  type_text?: string;
}

interface JobItem {
  job_id: string | number;
  job_title: string;
  is_urgent?: boolean;
  urgent_text?: string;
  job_tags?: string[];
  salary_info?: SalaryInfo;
  company?: Company;
}

interface JobCardPcProps {
  item: JobItem;
  index: number;
  currentIndex: number;
  changeDetail: (e: React.MouseEvent, index: number) => void;
}
import { setJobViewCookiesTracker, viewJobSourceTracker } from "components/ReportTracker/util";
import { setCookie, getCookie } from "helpers/cookies";

const JobCardPc = ({ item, index, currentIndex, changeDetail, cardType }) => {
  const lang = getLang();

  const { salary_info = {}, company = {}, recruiter = {} } = item || {};
  const { reply_label = "", reply_label_number = "" } = recruiter;
  let reply = reply_label;
  if (reply_label_number) {
    reply = reply?.replace(String(reply_label_number), `<span>${reply_label_number}</span>`);
  }

  const titleRef = useRef<HTMLHeadingElement>();
  const [visible, setVisible] = useState(false);
  const [moveIn, setMoveIn] = useState(false);

  const checkTextOverflow = () => {
    if (titleRef.current && cardType === "row") {
      const isTruncated = titleRef.current.scrollWidth > titleRef.current.clientWidth;
      setVisible(isTruncated);
      return;
    }

    setVisible(false);
  };

  useEffect(() => {
    checkTextOverflow();
  }, [item.job_title, cardType]);

  const handleJobCardTracker = () => {
    const source = getCookie("sessionid")
      ? viewJobSourceTracker.hotJobForLogin
      : viewJobSourceTracker.hotJobForUnLogin;

    setJobViewCookiesTracker(setCookie, {
      source,
      reco_from: item?.reco_from ? item?.reco_from : null,
      query_id: item?.query_id ? item?.query_id : null
    });
  };

  const handleOnClick = (e, index) => {
    changeDetail(e, index);
    if (item.job_url && cardType === "row") {
      handleJobCardTracker();
      window.open(`/${lang}${item.job_url}`, "_blank");
    }
  };

  return (
    <div
      key={item.job_id}
      className={`
        ${styles.listItem} 
        ${index === currentIndex && cardType !== "row" ? styles.listItemActive : ""} 
        ${styles.listItemRow}
      `}
      onClick={(e) => handleOnClick(e, index)}
      onMouseOver={() => setMoveIn(true)}
      onMouseOut={() => setMoveIn(false)}
    >
      <div className={styles.wrapper}>
        <div className={styles.jobHireTop}>
          <h3
            className={classNames({
              [styles.jobHireTopTitle]: true,
              [styles.jobHireTopTitleAndCardColumn]: cardType === "row"
            })}
          >
            {visible ? (
              <Tooltip title={item.job_title}>
                <span ref={titleRef}>{item.job_title}</span>
              </Tooltip>
            ) : (
              <span ref={titleRef}>{item.job_title}</span>
            )}

            {item?.is_urgent && (
              <span className={styles.jobHireTitleUrgent}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='15'
                  height='14'
                  viewBox='0 0 15 14'
                  fill='none'
                >
                  <path
                    d='M10.2416 1.75098H6.0334C5.83777 1.75098 5.6605 1.86618 5.58105 2.04494L2.97486 7.90887C2.82938 8.23621 3.06899 8.60492 3.4272 8.60492H6.09248L4.78982 13.1642C4.73302 13.363 4.98066 13.5049 5.12343 13.3553L11.7669 6.39552C12.0676 6.08051 11.8443 5.55872 11.4088 5.55872H9.13868L10.6844 2.46736C10.8489 2.13823 10.6096 1.75098 10.2416 1.75098Z'
                    fill='black'
                  />
                </svg>
                {item?.urgent_text}
              </span>
            )}
          </h3>
          <div className={styles.salary}>
            <span className={styles.salaryText}>
              {salary_info?.text}
              {salary_info?.type_text && (
                <span className={styles.month}>[{salary_info?.type_text}]</span>
              )}
            </span>
          </div>
          <div className={styles.listTag}>
            {item?.job_tags?.length > 0
              ? item?.job_tags.map((tag) => {
                  return (
                    <span className={styles.jobCardLocationItem} key={tag}>
                      {tag}
                    </span>
                  );
                })
              : null}
          </div>
        </div>

        {(moveIn && cardType === "column" && index !== currentIndex) ||
        (moveIn && cardType === "row") ? (
          <div className={styles.listCompany}>
            <div
              className={classNames({
                [styles.listCompanyLogo]: true,
                [styles.listCompanyLogoActive]: recruiter?.is_online
              })}
            >
              <Image src={recruiter?.avatar} alt={recruiter?.company_name} width={32} height={32} />
            </div>

            <a className={styles.jobHireCompanyName} href={company?.company_url} target='_blank'>
              <span className={styles.jobHireCompanyNameFullName}> {recruiter?.full_name} </span>
              <b>{recruiter?.job_title ? " · " : ""}</b>
              <span>{recruiter?.job_title ? recruiter?.job_title : ""}</span>
            </a>

            <span
              className={styles.listCompanyReply}
              dangerouslySetInnerHTML={{ __html: reply }}
            ></span>
          </div>
        ) : (
          <div className={classNames(styles.listCompany, styles.listCompanyRecruiter)}>
            <div
              className={classNames({
                [styles.listCompanyLogo]: true
              })}
            >
              <Image
                src={company?.company_logo}
                alt={company?.company_name}
                width={32}
                height={32}
              />
            </div>

            <p className={styles.jobHireRecruiterName}>{company?.company_name}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobCardPc;
