"use client";
import React, { useEffect } from "react";
import { useSharedData } from "bossjob-remote/dist/hooks";
import guestLogin from "helpers/guest";
const GetLogin = () => {
  const fileBaseToken = useSharedData<string | null>("FIREBASE_MESSAGING_TOKEN");
  useEffect(() => {
    if (fileBaseToken) {
      guestLogin(fileBaseToken).catch((error: Error) => {
        console.error(error);
      });
    }
  }, [fileBaseToken]);

  return null;
};
export default GetLogin;
