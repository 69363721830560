import { SelectProps } from "antd";
import { generatePairs, getOneLabel, getOneRegion, restoreOriginalObject } from "./helper";
import { formatOptions } from "../../helper";
import MultiSelect from "../../baseSelect/pc/MultiSelect";
import { useMemo } from "react";

type valueType = {
  region: string[];
  location: string[];
};

type PropsType = {
  label: string;
  location_filter: any[];
  value: valueType;
  searchBoardPlaceholder?: string;
  boardClearText?: string;
  boardApplyFiltersText?: string;
  wrapperClassName?: string;
  arrowIconStyle?: "dark" | "default";
  showWhereLabel?: boolean;
  realTimeRefresh?: boolean;
  whereLabelText?: string;
} & SelectProps;

function extractChildren(arr) {
  let result = [];
  let level = 0;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].children && arr[i].children.length === 1) {
      result.push(arr[i].children[0]);
      level = 1;
    } else if (arr[i].children && arr[i].children.length > 1) {
      return { result: arr, level: 0 };
    }
  }
  return { result, level };
}

const LocationSelectPc = ({
  placeholder,
  value,
  onChange,
  location_filter,
  wrapperClassName,
  ...rest
}: PropsType) => {
  const options = useMemo(() => {
    return formatOptions(location_filter, ["locations"]);
  }, [location_filter]);

  const { result: _options, level } = useMemo(() => {
    return extractChildren(options);
  }, [options]);

  const newValue = useMemo(() => {
    const newValue = generatePairs(value, options, level);
    return newValue;
  }, [value, options, level]);

  const generateChangeValue = (value) => {
    const changeValue = restoreOriginalObject(value, level);
    onChange(changeValue, options);
  };

  // console.log({ options });

  let oneLabel = getOneLabel(options, value.location?.[0]);

  if (!oneLabel && newValue.length === 1) {
    oneLabel = getOneRegion(options, value.region[0])?.children?.[0]?.label || "";
  }

  // console.log("newValue", newValue);

  return (
    <MultiSelect
      options={_options}
      placeholder={placeholder}
      value={newValue}
      onChange={generateChangeValue}
      oneLabel={oneLabel}
      wrapperClassName={wrapperClassName}
      {...rest}
    />
  );
};

export default LocationSelectPc;
