import React from "react";
import Image from "next/image";
import classNames from "classnames";

import styles from "../../../styles/index.mobile.module.scss";

import LinkClient from "./Link.client";
import { Recruiter } from "../types";

interface SalaryInfo {
  text?: string;
  type_text?: string;
}

interface Job {
  job_title?: string;
  is_urgent?: boolean;
  urgent_text?: string;
  job_url?: string;
  company_name?: string;
  job_tags?: string[];
  recruiter?: Recruiter;
  salary_info?: SalaryInfo;
}

interface JobCardMobileProps {
  job: Job;
  langKey: string;
  recruiter?: Recruiter;
}

const JobCardMobile = (props: JobCardMobileProps) => {
  const { job = {}, langKey } = props;
  const salary_info = job?.salary_info || {};
  const recruiter = (props.recruiter || job?.recruiter) as Recruiter;

  return (
    <li className={styles.jobCard}>
      <LinkClient className={styles.jobCardLink} href={`/${langKey}${job?.job_url}`} job={job}>
        <h4 className={styles.jobCardTitle}>
          {job?.job_title}{" "}
          {job?.is_urgent && (
            <span className={styles.jobCardUrgent}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='12'
                height='12'
                viewBox='0 0 12 12'
                fill='none'
              >
                <path
                  d='M8.56256 1.50049H4.95552C4.78785 1.50049 4.6359 1.59923 4.5678 1.75246L2.33392 6.77868C2.20922 7.05926 2.4146 7.3753 2.72164 7.3753H5.00616L3.8896 11.2833C3.84091 11.4537 4.05318 11.5753 4.17555 11.4471L9.86994 5.48152C10.1277 5.21152 9.93629 4.76427 9.56303 4.76427H7.61719L8.94206 2.11453C9.08312 1.83242 8.87797 1.50049 8.56256 1.50049Z'
                  fill='black'
                />
              </svg>
              {job?.urgent_text}
            </span>
          )}
        </h4>
        <div className={styles.jobCardSalary}>
          {salary_info?.text ? (
            <span className={styles.jobCardSalaryValue}>{salary_info?.text}</span>
          ) : null}
          {salary_info?.type_text ? (
            <span className={styles.jobCardSalaryType}>[{salary_info?.type_text}]</span>
          ) : null}
        </div>
        <p className={styles.jobCardCompany}>{job?.company_name}</p>
        <div className={styles.jobCardLocation}>
          {job?.job_tags?.length > 0
            ? job?.job_tags.map((item) => {
                return (
                  <span className={styles.jobCardLocationItem} key={item}>
                    {item}
                  </span>
                );
              })
            : null}
        </div>
        <div className={styles.jobCardLine}></div>
        <div className={styles.jobCardFooter}>
          <div className={styles.jobCardFooterAvatar}>
            <div
              className={classNames([
                styles.jobCardFooterAvatarImage,
                recruiter?.is_online === true ? "" : styles.offline
              ])}
            >
              <Image
                loading='lazy'
                src={recruiter?.avatar || ""}
                alt='avatar'
                width={24}
                height={24}
              />
            </div>
            {recruiter?.is_online ? (
              <span className={styles.jobCardFooterAvatarOnline}></span>
            ) : null}
          </div>
          <div className={styles.jobCardFooterInfo}>
            <div className={styles.jobCardFooterInfoText}>
              <span className={styles.jobCardFooterInfoName}>{recruiter?.full_name}</span>{" "}
              {recruiter?.job_title && (
                <>
                  <span className={styles.jobCardFooterInfoDot}>·</span>{" "}
                  <span className={styles.jobCardFooterInfoJobTitle}>{recruiter?.job_title}</span>
                </>
              )}
            </div>
          </div>
        </div>
      </LinkClient>
    </li>
  );
};

export default JobCardMobile;
