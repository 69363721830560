import React from "react";
import Image from "next/image";
import Link from "next/link";

import styles from "../../styles/index.mobile.module.scss";
import LinkToCompany from "./LinkToCompany.client";

interface IProps {
  company: any;
  langKey: string;
  device_udid: string;
}

const CompanyCardMobile = (props: IProps) => {
  const { langKey, device_udid } = props;
  const company = props?.company || {};

  const company_tags = company?.company_tags || [];
  const company_tags_html = company_tags
    .map((item) => `<span>${item}</span>`)
    .join(`<span class="${styles.companyCardDot}">·</span>`);

  return (
    <LinkToCompany
      isMobile={true}
      company={company}
      className={styles.companyCard}
      href={`/${langKey}${company?.company_url}`}
      device_udid={device_udid}
    >
      <div className={styles.companyImage}>
        <Image
          fill
          loading='lazy'
          alt={company?.company_name}
          src={company?.company_logo}
          sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
        />
      </div>
      <div className={styles.companyCardContent}>
        <div className={styles.companyCardTitle}>{company?.company_name}</div>
        <div
          className={styles.companyCardInfo}
          dangerouslySetInnerHTML={{ __html: company_tags_html }}
        ></div>
        <div className={styles.companyCardFooter}>
          <span className={styles.companyFooterJobs}>{company?.jobs_num}</span>
          <span className={styles.companyFooterOpening}>{company?.job_num_text}</span>
        </div>
      </div>
    </LinkToCompany>
  );
};

export default CompanyCardMobile;
