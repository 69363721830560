"use client";
import { Carousel } from "antd";
import Image from "next/image";
import Link from "next/link";

const contentStyle: React.CSSProperties = {
  margin: 0,
  height: "332px",
  color: "#fff",
  lineHeight: "332px",
  textAlign: "center",
  background: "#E0EEFF",
  cursor: "pointer"
};

const CarouselPcClient = ({ bannerList }) => {
  if (!bannerList || bannerList?.length === 0) {
    return null;
  }
  const showDot = bannerList?.length > 1;
  // console.log("bannerList", bannerList);

  return (
    <Carousel autoplay={showDot} autoplaySpeed={3000} dots={showDot}>
      {bannerList.map((item) => (
        <div style={contentStyle} key={item.id}>
          {item?.url ? (
            <Link href={item.url} target='_blank'>
              <Image
                src={item.picture_url}
                alt={item.banner_name}
                width={540}
                height={332}
                priority={true}
              />
            </Link>
          ) : (
            <Image
              src={item.picture_url}
              alt={item.banner_name}
              width={540}
              height={332}
              priority={true}
            />
          )}
        </div>
      ))}
    </Carousel>
  );
};

export default CarouselPcClient;
