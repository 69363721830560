"use client";
import React from "react";
import Link from "next/link";
import { addEventTag } from "helpers/utilities";
import { setJobViewCookiesTracker, viewJobSourceTracker } from "components/ReportTracker/util";
import { setCookie, getCookie } from "helpers/cookies";

interface IProps {
  children: React.ReactNode;
  href: string;
  className?: string;
  [key: string]: any;
}

const LinkToJob = (props: IProps) => {
  const { children, isMobile, job, ...rest } = props;
  // const sessionId = getCookie("sessionid");
  const source = viewJobSourceTracker.homeForCompanies;

  const onClick = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    addEventTag("hp_top_companies_job_card_click");
    setJobViewCookiesTracker(setCookie, {
      source: source,
      reco_from: job?.reco_from ? job?.reco_from : null,
      query_id: job?.query_id ? job?.query_id : null
    });
  };
  return (
    <Link {...rest} onClick={onClick}>
      {children}
    </Link>
  );
};

export default LinkToJob;
